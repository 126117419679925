export default {
    onCreate() {
        this.state = {
            formState: 'waiting'
        }
        return this.state;
    },
    async handleSubmit(e, form) {
        this.state.formState = 'transmitting';
        e.preventDefault();
        const res = await fetch('/contact', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(Object.fromEntries(new FormData(form)))
        });
        const json = await res.json();

        if (json.success) {
            this.state.formState = 'success';
        } else {
            this.state.formState = 'failure';
            // Reset form to enter new message?
            setTimeout(function () {
                this.state.formState = 'waiting';
            }.bind(this), 2000);
        }
    }
}

