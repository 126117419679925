<section id="contact" class="contact" data-form-state=state.formState>
    <i class="contact__bg _left" />
    <i class="contact__bg _right" />
    <form method="POST" action="/contact" on-submit('handleSubmit') class="container">
        <fieldset>
            <legend>Press inquiries or questions?</legend>
            <label>
                Name
                <input type="text" placeholder="Your full name" name="name" />
            </label>
            <label>
                E-mail
                <input type="text" placeholder="Your e-mail address" name="email" />
            </label>
            <label>
                Message
                <textarea type="text" rows="5" placeholder="Your message" name="message" />
            </label>
            <button class="btn-primary" type="submit">Submit</button>
        </fieldset>
    </form>
    <div class="status_message">
        <p>Sending your message...</p>
        <p>Message sent successfully!</p>
        <p>Error sending message. Please try again later.</p>
    </div>
</section>